.App {
  .routes {
    display: flex;
  }
  .header {
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) {
    .header {
      min-width: 140px;
      min-height: 100vh;
    }
  }

  @media screen and (max-width: 767px) {
    .routes {
      flex-direction: column;

      .container {
        position: sticky;
        z-index: 10;
        top: 0;
        left: 0;
        overflow-x: hidden;
        background-color: #252525;
        display: flex;
        padding: 5px;
      }
      .header {
        align-self: center;
        margin-left: auto;
        margin-right: 15px;
      }
    }
  }
}
