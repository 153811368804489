.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.CalendarWrapper {
  width: 100%;
  height: 60%;
  max-height: 60%;
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  justify-content: left;
  align-items: left;
  background: white;
  flex-direction: column;
  box-shadow: 0 0 3px #ccc;
}
