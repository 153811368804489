@media screen and (min-width: 768px) {
  .navContainer {
    height: 100vh;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    background-color: #252525;
  }
}

a {
  text-decoration: none;
  color: white;
  text-align: left;
}

.top {
  text-align: center;
  .logo-container {
    .logo {
      height: auto;
      width: 80px;
      padding: 5px;
    }
  }
}

.bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-end;
}
